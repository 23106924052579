function AdminMainPage () {
  console.log(localStorage.getItem('token') + " mda")
  return (
    <div>
      <br></br>
      <div>
        <h1>Admin page</h1>
        <br></br>
      </div>
    </div>
  )
}

export default AdminMainPage;
